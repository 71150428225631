import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';
import { StaticImage } from "gatsby-plugin-image"
import { MailIcon, PhoneIcon } from '@heroicons/react/outline'

const Index = () => (
  <Layout>
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10">
      {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
      <div className="max-w-6xl mx-auto">
        <div className="overflow-hidden shadow sm:rounded-lg bg-white">
          <div className="px-4 py-5 sm:p-6">
            <div className="bg-white">
              <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                <div className="max-w-lg mx-auto md:max-w-none md:grid md:grid-cols-2 md:gap-8">
                  <div>
                    <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">Soporte de ventas</h2>
                    <div className="mt-3">
                      <p className="text-lg text-gray-500 mb-5">
                        Para cualquier contacto o consulta sobre nuestros servicios favor comunicarse con
                      </p>
                    </div>
                    <div className="mt-9">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <PhoneIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3 text-base text-gray-500">
                          <p>591 67547717</p>
                          <p className="mt-1">Lunes - Viernes  9:00 - 18:00</p>
                        </div>
                      </div>
                      <div className="mt-6 flex">
                        <div className="flex-shrink-0">
                          <MailIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3 text-base text-gray-500">
                          <p>mauricio.arias.subieta@gmail.com</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-12 sm:mt-16 md:mt-0">
                    <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">Soporte tecnico</h2>
                    <div className="mt-3 mb-5">
                      <p className="text-lg text-gray-500">
                        Ante cualquier inconveniene técnico no dude en contactarnos.
                      </p>
                    </div>
                    <div className="mt-9">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <PhoneIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3 text-base text-gray-500">
                        <p>591 67547717</p>
                          <p className="mt-1">Lunes - Viernes  8:00 - 19:00</p>
                        </div>
                      </div>
                      <div className="mt-6 flex">
                        <div className="flex-shrink-0">
                          <MailIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3 text-base text-gray-500">
                          <p>info@smartbo.app</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout >
);

export default Index;
